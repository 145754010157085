<script setup>
    import { computed, defineEmits, defineProps, onMounted, watch, ref } from 'vue'

    const emit = defineEmits(['update:modelValue'])
    const props = defineProps(['modelValue'])

    const model = computed(() => props.modelValue)

    const periods = ref([])

    watch(periods, (newValue) => {
        if (newValue) {
            emit(
                'update:modelValue', 
                newValue
            )
        }
    })

    onMounted(() => {
        periods.value = model.value
    })

    const remove = (slotProps) => {
        periods.value.splice(slotProps.index, 1)
    }
</script>

<template>
    <DataTable :value="periods" responsiveLayout="scroll">
        <Column header="หัวข้อ">
            <template #body="slotProps">
                <InputText v-model="slotProps.data.title" type="text" />
            </template>
        </Column>

        <Column header="วันเริ่ม">
            <template #body="slotProps">
                <Calendar v-model="slotProps.data.start_date" dateFormat="yy-mm-dd" />
            </template>
        </Column>

        <Column header="วันสิ้นสุด">
            <template #body="slotProps">
                <Calendar v-model="slotProps.data.end_date" dateFormat="yy-mm-dd" />
            </template>
        </Column>

        <Column header="ราคาผู้ใหญ่">
            <template #body="slotProps">
                <InputText v-model="slotProps.data.adult_price" type="text" />
            </template>
        </Column>

        <Column header="ราคาเด็ก">
            <template #body="slotProps">
                <InputText v-model="slotProps.data.child_price" type="text" />
            </template>
        </Column>

        <Column header="ราคาเต็มผู้ใหญ่">
            <template #body="slotProps">
                <InputText v-model="slotProps.data.adult_full_price" type="text" />
            </template>
        </Column>

        <Column header="ราคาเต็มเด็ก">
            <template #body="slotProps">
                <InputText v-model="slotProps.data.child_full_price" type="text" />
            </template>
        </Column>

        <Column header="เครื่องมือ">
            <template #body="slotProps">
                <Button icon="pi pi-times" class="p-button-rounded p-button-danger" @click="remove(slotProps)" />
            </template>
        </Column>
    </DataTable>
</template>
