<script setup>
    import { computed, defineEmits, defineProps, onMounted, watch, ref } from 'vue'
    import { useCategoryStore } from "@/store/category"

    const categoryStore = useCategoryStore()

    const emit = defineEmits(['update:modelValue'])
    const props = defineProps(['modelValue'])

    const model = computed(() => props.modelValue)

    const categories = ref([])
    
    const defaultCategories = ref([])
    const filteredCategories = ref([])
    const searchCategory = (event) => {
        setTimeout(() => {
            if (event.query.trim().length) {
                categoryStore.index({ search: event.query.trim() }).then(({ data }) => {
                    filteredCategories.value = data.categories.data
                        .filter(x => !categories.value.find(y => y.id == x.id))
                })
            } else {
                filteredCategories.value = defaultCategories.value
                    .filter(x => !categories.value.find(y => y.id == x.id))
            }
        }, 250)
    }

    watch(categories, (newValue) => {
        if (newValue) {
            emit(
                'update:modelValue', 
                newValue
            )
        }
    })

    onMounted(() => {
        categories.value = model.value

        categoryStore.index().then(({ data }) => {
            defaultCategories.value = data.categories.data
        })
    })
</script>

<template>
    <AutoComplete :multiple="true" v-model="categories" :suggestions="filteredCategories" @complete="searchCategory($event)" :dropdown="true" optionLabel="name" forceSelection />
</template>
