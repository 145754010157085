<script setup>
    import { computed, defineEmits, defineProps, onMounted, watch, ref } from 'vue'
    import { useAirlineStore } from "@/store/airline"

    const airlineStore = useAirlineStore()

    const emit = defineEmits(['update:modelValue'])
    const props = defineProps(['modelValue', 'airline'])

    const airline = computed(() => props.airline)

    const airlines = ref([])
    
    const defaultAirlines = ref([])
    const filteredAirlines = ref([])
    const searchAirline = (event) => {
        setTimeout(() => {
            if (event.query.trim().length) {
                airlineStore.index({ search: event.query.trim() }).then(({ data }) => {
                    filteredAirlines.value = data.airlines.data
                })
            } else {
                filteredAirlines.value = [ ...defaultAirlines.value ]
            }
        }, 250)
        
    }

    watch(airlines, (newValue) => {
        if (newValue) {
            emit(
                'update:modelValue', 
                newValue.id
            )
        }
    })

    onMounted(() => {
        airlines.value = airline.value

        airlineStore.index({ search: '' }).then(({ data }) => {
            defaultAirlines.value = data.airlines.data
        })
    })
</script>

<template>
    <AutoComplete v-model="airlines" :suggestions="filteredAirlines" @complete="searchAirline($event)" :dropdown="true" optionLabel="name" forceSelection />
</template>
