<script setup>
    import { computed, defineEmits, defineProps, onMounted, watch, ref } from 'vue'
    import { useCollectionStore } from "@/store/collection"

    const collectionStore = useCollectionStore()

    const emit = defineEmits(['update:modelValue'])
    const props = defineProps(['modelValue'])

    const model = computed(() => props.modelValue)

    const collections = ref([])
    
    const defaultCollections = ref([])
    const filteredCollections = ref([])
    const searchCollection = (event) => {
        setTimeout(() => {
            if (event.query.trim().length) {
                collectionStore.index({ search: event.query.trim() }).then(({ data }) => {
                    filteredCollections.value = data.collections.data
                        .filter(x => !collections.value.find(y => y.id == x.id))
                })
            } else {
                filteredCollections.value = defaultCollections.value
                    .filter(x => !collections.value.find(y => y.id == x.id))
            }
        }, 250)
    }

    watch(collections, (newValue) => {
        if (newValue) {
            emit(
                'update:modelValue', 
                newValue
            )
        }
    })

    onMounted(() => {
        collections.value = model.value

        collectionStore.index().then(({ data }) => {
            defaultCollections.value = data.collections.data
        })
    })
</script>

<template>
    <AutoComplete :multiple="true" v-model="collections" :suggestions="filteredCollections" @complete="searchCollection($event)" :dropdown="true" optionLabel="name" forceSelection />
</template>
