import { defineStore } from 'pinia'

import axios from "@/plugins/axios"

require('dotenv').config()
const VUE_APP_ENDPOINT_URL = process.env.VUE_APP_ENDPOINT_URL

export const useAirlineStore = defineStore('airline', {
  actions: {
    index: ({ search = null, page = 1 }) => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/airlines?page=${page}${ search ? '&search=' + search : '' }`),
    show: (id) => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/airlines/${id}`),
		store: (airline) => axios.post(`${VUE_APP_ENDPOINT_URL}/api/admin/airlines`, airline),
		update: (id, airline) => axios.patch(`${VUE_APP_ENDPOINT_URL}/api/admin/airlines/${id}`, airline),
  }
})
