<script setup>
    import { computed, defineEmits, defineProps, onMounted, watch, ref } from 'vue'
    import { useAttractionStore } from "@/store/attraction"

    const attractionStore = useAttractionStore()

    const emit = defineEmits(['update:modelValue'])
    const props = defineProps(['modelValue'])

    const model = computed(() => props.modelValue)

    const attractions = ref([])
    
    const defaultAttractions = ref([])
    const filteredAttractions = ref([])
    const searchAttraction = (event) => {
        setTimeout(() => {
            if (event.query.trim().length) {
                attractionStore.index({ search: event.query.trim() }).then(({ data }) => {
                filteredAttractions.value = data.attractions.data
                    .filter(x => !attractions.value.find(y => y.id == x.id))
            })
            } else {
                filteredAttractions.value = defaultAttractions.value
                    .filter(x => !attractions.value.find(y => y.id == x.id))
            }
        }, 250)
    }

    watch(attractions, (newValue) => {
        if (newValue) {
            emit(
                'update:modelValue', 
                newValue.map((x) => x.id)
            )
        }
    })

    onMounted(() => {
        attractionStore.ids({ ids: model.value }).then(({ data }) => {
            attractions.value = data.attractions

            if (! attractions.value) {
                attractions.value = []
            }
        })

        attractionStore.index().then(({ data }) => {
            defaultAttractions.value = data.attractions.data
        })
    })
</script>

<template>
    <AutoComplete :multiple="true" v-model="attractions" :suggestions="filteredAttractions" @complete="searchAttraction($event)" :dropdown="true" optionLabel="name" forceSelection />
</template>
