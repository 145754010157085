<script setup>
    import { computed, defineEmits, defineProps, reactive, watch } from 'vue'

    const paymentOptions = [
        {name: 'ไม่มีมื้ออาหาร', value: 0},
        {name: 'มื้อเพื่อคุณ', value: 1},
        {name: 'มื้ออิสระ', value: 2},
    ]

    const emit = defineEmits(['update:modelValue'])
    const props = defineProps(['modelValue', 'mealAmount', 'travelAmount'])

    const model = computed(() => props.modelValue)
    const mealAmount = computed(() => props.mealAmount)
    const travelAmount = computed(() => props.travelAmount)

    const meals = reactive([])
    for (let i = 1; i <= travelAmount.value; i++) {
        meals.push({
            day: i,
            breakfast: typeof model.value[i-1] === 'undefined' ? 0 : parseInt(model.value[i-1].breakfast),
            lunch: typeof model.value[i-1] === 'undefined' ? 0 : parseInt(model.value[i-1].lunch),
            dinner: typeof model.value[i-1] === 'undefined' ? 0 : parseInt(model.value[i-1].dinner),
        })
    }

    watch(meals, (newValue) => {
        emit('update:modelValue', newValue)
    })
</script>

<template>
    <DataTable :value="meals" responsiveLayout="scroll">
        <Column field="day" header="วันที่"></Column>
        <Column field="breakfast" header="เช้า">
            <template #body="slotProps">
                <SelectButton v-model="slotProps.data.breakfast" :options="paymentOptions" optionLabel="name" optionValue="value" class="custom-color" />
            </template>
        </Column>
        <Column field="lunch" header="เที่ยง">
            <template #body="slotProps">
                <SelectButton v-model="slotProps.data.lunch" :options="paymentOptions" optionLabel="name" optionValue="value" class="custom-color" />
            </template>
        </Column>
        <Column field="dinner" header="เย็น">
            <template #body="slotProps">
                <SelectButton v-model="slotProps.data.dinner" :options="paymentOptions" optionLabel="name" optionValue="value" class="custom-color" />
            </template>
        </Column>
    </DataTable>

    <br/>

    <div>มื่ออาหาร {{ mealAmount }} มื้อ</div>
    <div>วันท่องเที่ยว {{ travelAmount }} วัน</div>


</template>

<style lang="scss" scoped>
    ::v-deep {
        .custom-color > div:nth-child(1).p-highlight { background-color: grey !important; border-color: grey !important; }
        .custom-color > div:nth-child(2).p-highlight { background-color: green !important; border-color: green !important; }
        .custom-color > div:nth-child(3).p-highlight { background-color: orange !important; border-color: orange !important; }
        .p-button.p-component { padding: 0 }
    }
    
</style>