import { defineStore } from 'pinia'

import axios from "@/plugins/axios"

require('dotenv').config()
const VUE_APP_ENDPOINT_URL = process.env.VUE_APP_ENDPOINT_URL

export const useAttractionStore = defineStore('attraction', {
  actions: {
    index: ({ search = null, page = 1 } = {}) => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/attractions?page=${page}${ search ? '&search=' + search : '' }`),
    ids: ({ ids }) => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/attractions/ids`, { params: { ids } }),
    show: (id) => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/attractions/${id}`),
		store: (attraction) => axios.post(`${VUE_APP_ENDPOINT_URL}/api/admin/attractions`, attraction),
		update: (id, attraction) => axios.patch(`${VUE_APP_ENDPOINT_URL}/api/admin/attractions/${id}`, attraction),
  }
})
