<script setup>
    import { computed, defineEmits, defineProps, onMounted, reactive, watch } from 'vue'

    const emit = defineEmits(['update:modelValue'])
    const props = defineProps(['modelValue', 'travelAmount'])

    const model = computed(() => props.modelValue)
    const travelAmount = computed(() => props.travelAmount)

    const details = reactive({})

    watch(details, (newValue) => {
        if (newValue) {
            emit(
                'update:modelValue', 
                newValue.value
            )
        }
    })

    onMounted(() => {
        details.value = model.value
        if (details.value.length < travelAmount.value) {
            const length = travelAmount.value - details.value.length
            for (let i = 1; i <= length; i++) {
                details.value.push({
                    id: null,
                    title: '',
                    contents: '',
                })
            }
        } else {
            details.value.length = travelAmount.value
        }
    })
</script>

<template>
    <div>วันท่องเที่ยว {{ travelAmount }} วัน</div>
    <br/>
    <Accordion :multiple="true" :activeIndex="[0]">
        <AccordionTab :header="`วันที่ ${(index + 1)}`" v-for="(detail, index) in details.value" :key="index">
            <InputText v-model="detail.title" />
            <br/><br/>
            <Editor v-model="detail.contents" editorStyle="height: 500px" />
        </AccordionTab>
    </Accordion>
    
</template>
