import { defineStore } from 'pinia'

import axios from "@/plugins/axios"

require('dotenv').config()
const VUE_APP_ENDPOINT_URL = process.env.VUE_APP_ENDPOINT_URL

export const useCategoryStore = defineStore('category', {
  actions: {
    index: ({ search = null, page = 1 } = {}) => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/categories?page=${page}${ search ? '&search=' + search : '' }`),
    show: (id) => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/categories/${id}`),
		store: (category) => axios.post(`${VUE_APP_ENDPOINT_URL}/api/admin/categories`, category),
		update: (id, category) => axios.patch(`${VUE_APP_ENDPOINT_URL}/api/admin/categories/${id}`, category),
  }
})
