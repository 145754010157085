<script setup>
    import { computed, defineEmits, defineProps, onMounted, watch, ref } from 'vue'
    import { useHotelStore } from "@/store/hotel"

    const hotelStore = useHotelStore()

    const emit = defineEmits(['update:modelValue'])
    const props = defineProps(['modelValue', 'day', 'hotel'])

    const day = computed(() => props.day)
    const hotel = computed(() => props.hotel)

    const model = ref([])
    
    const defaultHotels = ref([])
    const filteredHotels = ref([])
    const searchHotel = (event) => {
        setTimeout(() => {
            if (event.query.trim().length) {
                hotelStore.index({ search: event.query.trim() }).then(({ data }) => {
                    filteredHotels.value = data.hotels.data
                })
            } else {
                filteredHotels.value = [ ...defaultHotels.value ]
            }
        }, 250)
        
    }

    watch(model, (newValue) => {
        if (newValue) {
            if (typeof newValue === 'object') {
                newValue.pivot = { hotel_id: newValue.id, tour_id: hotel.value.id, day: day }
                newValue.pivot.hotel_id = newValue.id
                emit(
                    'update:modelValue', 
                    newValue
                )
            }
        } else {
            emit(
                'update:modelValue', 
                {}
            )
        }
    })

    onMounted(() => {
        model.value = hotel.value

        hotelStore.index({ search: '' }).then(({ data }) => {
            defaultHotels.value = data.hotels.data
        })
    })
</script>

<template>
    <div class="flex">
        <div class="col-3">
            วันที่ {{ day }} 
            <span v-if="model && model.star">
                , ระดับ {{ model.star }} ดาว
            </span>
        </div>
        <div class="col-9">
            <AutoComplete v-model="model" :suggestions="filteredHotels" @complete="searchHotel($event)" :dropdown="true" optionLabel="name" />
        </div>
    </div>
</template>
