import { defineStore } from 'pinia'

import axios from "@/plugins/axios"

require('dotenv').config()
const VUE_APP_ENDPOINT_URL = process.env.VUE_APP_ENDPOINT_URL

export const useHotelStore = defineStore('hotel', {
  actions: {
    index: ({ search = null, page = 1 }) => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/hotels?page=${page}${ search ? '&search=' + search : '' }`),
    show: (id) => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/hotels/${id}`),
		store: (hotel) => axios.post(`${VUE_APP_ENDPOINT_URL}/api/admin/hotels`, hotel),
		update: (id, hotel) => axios.patch(`${VUE_APP_ENDPOINT_URL}/api/admin/hotels/${id}`, hotel),
  }
})
