<script setup>
    import { onMounted, reactive, ref } from 'vue'
    import { useRoute } from 'vue-router'
    import { useForm } from 'vee-validate'

    import { useTourStore } from "@/store/tour"

    import * as yup from 'yup'
    import MZBreadcrumb from '@/components-chillpainai/MZBreadcrumb.vue'

    import { RESTfulModule } from '@/modules/RESTfulModule'

    import Airline from '@/components-chillpainai/Airline.vue'
    import Attractions from '@/components-chillpainai/Attractions.vue'
    import Categories from '@/components-chillpainai/Categories.vue'
    import Collections from '@/components-chillpainai/Collections'
    import Meals from '@/components-chillpainai/Meals.vue'
    import StatusBadgeExclude from '@/components-chillpainai/StatusBadgeExclude.vue'
    import StatusBadgeInclude from '@/components-chillpainai/StatusBadgeInclude.vue'
    import TourDetails from '@/components-chillpainai/TourDetails.vue'
    import TourHotels from '@/components-chillpainai/TourHotels.vue'
    import TourPeriods from '@/components-chillpainai/TourPeriods.vue'

    import logo from '@/assets/logo.png'

    import { VueDraggableNext } from 'vue-draggable-next'
    const draggable = VueDraggableNext

    const restful = new RESTfulModule()

    const route = useRoute()

    const tourStore = useTourStore()

    const initial = ref(false)

    const form = reactive({
        airline: { loading: false }, 
        attractions: { loading: false },
        categories: { loading: false },
        collections: { loading: false },
        conditionNormal: { loading: false },
        conditionRefund: { loading: false },
        details: { loading: false },
        gallery: { loading: false },
        hotels: { loading: false }, 
        highlight: { loading: false }, 
        main: { loading: false },
        meals: { loading: false },
        others: { loading: false },
        pdf: { loading: false, upload: false },
        periods: { loading: false },
        pictureSocialNetwork: { loading: false },
        statusBadge: { loading: false },
    })

    const { errors, useFieldModel } = useForm({
        validationSchema: yup.object({
            code: yup.string().required(),
            active: yup.boolean(),
            title: yup.string().required(),
            keywords: yup.string().required(),
            description: yup.string().required(),
            youtube_id: yup.string().nullable(),
            location: yup.string().nullable(),
            calendar_name: yup.string().nullable(),
            market_price: yup.string().nullable(),

            airline_id: yup.string().nullable(),
            airline_badge: yup.string().nullable(),
            
            travel_style_type: yup.string().nullable(),
            travel_amount: yup.number().nullable(),
            hotel_amount: yup.number().nullable(),
            meal_amount: yup.number().nullable(),
            meals: yup.string().nullable(), // JSON
            attraction_ids: yup.string().nullable(), // JSON
            attraction_amount: yup.number().nullable(),
            activity_amount: yup.number().nullable(),
            shop_amount: yup.number().nullable(),
            free_day_amount: yup.number().nullable(),

            included_status_badge_ids: yup.string().nullable(), // JSON
            excluded_status_badge_ids: yup.string().nullable(), // JSON
            condition_normal: yup.string().nullable(),
            condition_refund: yup.string().nullable(),

            local_guide_price: yup.number().nullable(),
            flight_start_time: yup.string().nullable(),
            flight_end_time: yup.string().nullable(),
            highlight: yup.string().nullable(),
        })
    })

    const model = reactive({
        code: useFieldModel('code'),
        active: useFieldModel('active'),
        title: useFieldModel('title'),
        keywords: useFieldModel('keywords'),
        description: useFieldModel('description'),
        youtube_id: useFieldModel('youtube_id'),
        location: useFieldModel('location'),
        calendar_name: useFieldModel('calendar_name'),
        market_price: useFieldModel('market_price'),

        airline_id: useFieldModel('airline_id'),
        airline_badge: useFieldModel('airline_badge'),

        travel_style_type: useFieldModel('travel_style_type'),
        travel_amount: useFieldModel('travel_amount'),
        hotel_amount: useFieldModel('hotel_amount'),
        meal_amount: useFieldModel('meal_amount'),
        meals: useFieldModel('meals'),
        attraction_ids: useFieldModel('attraction_ids'),
        attraction_amount: useFieldModel('attraction_amount'),
        activity_amount: useFieldModel('activity_amount'),
        shop_amount: useFieldModel('shop_amount'),
        free_day_amount: useFieldModel('free_day_amount'),

        included_status_badge_ids: useFieldModel('included_status_badge_ids'),
        excluded_status_badge_ids: useFieldModel('excluded_status_badge_ids'),
        condition_normal: useFieldModel('condition_normal'),
        condition_refund: useFieldModel('condition_refund'),
        
        local_guide_price: useFieldModel('local_guide_price'),
        flight_start_time: useFieldModel('flight_start_time'),
        flight_end_time: useFieldModel('flight_end_time'),
        highlight: useFieldModel('highlight'),

        periods: {},
        details: {},

        hotels: {},
    })

    const filePDF = ref({})
    const pictureSocialNetworkFacebook = ref({})
    const pictureSocialNetworkTwitter = ref({})
    const pictureSocialNetworkInstagram = ref({})
    const pictureSocialNetworkLine = ref({})

    const galleryProgressValue = ref(-1)

    const modelDefault = reactive({})

    const handleFileUploadPDF = (event) => {
        const file = event.target.files[0]
        filePDF.value = file

        form.pdf.upload = true
    }

    const handlePictureSocialNetworkFacebook = (event) => {
        const file = event.target.files[0]
        pictureSocialNetworkFacebook.value = file
    }

    const handlePictureSocialNetworkTwitter = (event) => {
        const file = event.target.files[0]
        pictureSocialNetworkTwitter.value = file
    }

    const handlePictureSocialNetworkInstagram = (event) => {
        const file = event.target.files[0]
        pictureSocialNetworkInstagram.value = file
    }

    const handlePictureSocialNetworkLine = (event) => {
        const file = event.target.files[0]
        pictureSocialNetworkLine.value = file
    }

    const galleryUploader = async (event) => {
        galleryProgressValue.value = 0
        for (const element of event.files) {
            await restful.upload({ 
                id: route.params.id,
                loading: form.gallery,
                file: element, 
                field: 'file',
                store: tourStore.updateGallery
            }).then((response) => {
                model.gallery = response.tour.gallery
                galleryProgressValue.value += 100 / event.files.length
            })
        }

        setTimeout(() => {
            galleryProgressValue.value = -1
        }, 3000)
    }

    const galleryRemove = (image) => restful.updateCustom({
        data: { url: image },
        id: route.params.id,
        keys: ['gallery'],
        loading: form.gallery,
        model: model,
        modelDefault: modelDefault,
        name: 'tour',
        store: tourStore.updateGalleryRemove,
    })

    const galleryReorder = () => restful.update({
        id: route.params.id,
        keys: ['gallery'],
        loading: form.gallery,
        model: model,
        modelDefault: modelDefault,
        name: 'tour',
        store: tourStore.update,
    })
    
    const updateAttractions = () => restful.update({
        id: route.params.id,
        keys: ['attraction_ids'],
        loading: form.attractions,
        model: model,
        modelDefault: modelDefault,
        name: 'tour',
        store: tourStore.update,
    })

    const updateAirline = () => restful.update({
        id: route.params.id,
        keys: ['airline_id', 'airline', 'airline_badge', 'location', 'calendar_name', 'market_price'],
        loading: form.airline,
        model: model,
        modelDefault: modelDefault,
        name: 'tour',
        store: tourStore.update,
    })

    const updateCategories = () => restful.update({
        id: route.params.id,
        keys: ['categories'],
        loading: form.categories,
        model: model,
        modelDefault: modelDefault,
        name: 'tour',
        store: tourStore.updateCategories,
    })

    const updateCollections = () => restful.update({
        id: route.params.id,
        keys: ['collections'],
        loading: form.collections,
        model: model,
        modelDefault: modelDefault,
        name: 'tour',
        store: tourStore.updateCollections,
    })

    const updateConditionNormal = () => restful.update({
        id: route.params.id,
        keys: ['condition_normal'],
        loading: form.conditionNormal,
        model: model,
        modelDefault: modelDefault,
        name: 'tour',
        store: tourStore.update,
    })

    const updateConditionRefund = () => restful.update({
        id: route.params.id,
        keys: ['condition_refund'],
        loading: form.conditionRefund,
        model: model,
        modelDefault: modelDefault,
        name: 'tour',
        store: tourStore.update,
    })

    const updateDetails = () => restful.update({
        id: route.params.id,
        keys: ['details'],
        loading: form.details,
        model: model,
        modelDefault: modelDefault,
        name: 'tour',
        store: tourStore.updateDetails,
    })

    const updateHighlight = () => restful.update({
        id: route.params.id,
        keys: ['travel_style_type', 'meal_amount', 'travel_amount', 'hotel_amount', 'attraction_amount', 'activity_amount', 'shop_amount', 'free_day_amount'],
        loading: form.highlight,
        model: model,
        modelDefault: modelDefault,
        name: 'tour',
        store: tourStore.update,
    }).then(() => {
        updateTourHotel()
    })

    const updateHotels = () => restful.update({
        id: route.params.id,
        keys: ['hotels'],
        loading: form.hotels,
        model: model,
        modelDefault: modelDefault,
        name: 'tour',
        store: tourStore.updateHotels,
    })

    const updateMain = () => restful.update({
        id: route.params.id,
        keys: ['code', 'active', 'title', 'keywords', 'description', 'youtube_id'],
        loading: form.main,
        model: model,
        modelDefault: modelDefault,
        name: 'tour',
        store: tourStore.update,
    })

    const updateMeals = () => restful.update({
        id: route.params.id,
        keys: ['meals'],
        loading: form.meals,
        model: model,
        modelDefault: modelDefault,
        name: 'tour',
        store: tourStore.update,
    })
    
    const updateOthers = () => restful.update({
        id: route.params.id,
        keys: ['local_guide_price', 'flight_start_time', 'flight_end_time', 'highlight'],
        loading: form.others,
        model: model,
        modelDefault: modelDefault,
        name: 'tour',
        store: tourStore.update,
    })

    const updatePDF = () => {
        form.pdf.loading = true
        
        restful.upload({ 
            id: route.params.id,
            loading: form.pdf,
            file: filePDF.value,
            field: 'file',
            store: tourStore.updatePDF 
        }).then((response) => {
            form.pdf.loading = false
            form.pdf.upload = false
            filePDF.value = {}
            model.pdf_url = response.tour.pdf_url
        })
    }

    const updatePeriods = () => restful.update({
        id: route.params.id,
        keys: ['periods'],
        loading: form.periods,
        model: model,
        modelDefault: modelDefault,
        name: 'tour',
        store: tourStore.updatePeriods,
    })

    const updatePictureSocialNetwork = () => {
        const count = ref(0)
        form.pictureSocialNetwork.loading = true

        if (pictureSocialNetworkFacebook.value.name) {
            count.value += 1

            restful.upload({ 
                id: route.params.id,
                loading: form.pictureSocialNetwork,
                file: pictureSocialNetworkFacebook.value, 
                field: 'facebook',
                store: tourStore.updatePictureSocialNetwork
            }).then((response) => {
                pictureSocialNetworkFacebook.value = {}

                model.picture_facebook_url = response.tour.picture_facebook_url

                count.value -= 1
                if (count.value === 0) {
                    form.pictureSocialNetwork.loading = false
                }
            })
        }

        if (pictureSocialNetworkTwitter.value.name) {
            count.value += 1

            restful.upload({ 
                id: route.params.id,
                loading: form.pictureSocialNetwork,
                file: pictureSocialNetworkTwitter.value, 
                field: 'twitter',
                store: tourStore.updatePictureSocialNetwork
            }).then((response) => {
                pictureSocialNetworkTwitter.value = {}

                model.picture_twitter_url = response.tour.picture_twitter_url

                count.value -= 1
                if (count.value === 0) {
                    form.pictureSocialNetwork.loading = false
                }
            })
        }

        if (pictureSocialNetworkInstagram.value.name) {
            count.value += 1

            restful.upload({ 
                id: route.params.id,
                loading: form.pictureSocialNetwork,
                file: pictureSocialNetworkInstagram.value, 
                field: 'instagram',
                store: tourStore.updatePictureSocialNetwork
            }).then((response) => {
                pictureSocialNetworkInstagram.value = {}

                model.picture_instagram_url = response.tour.picture_instagram_url

                count.value -= 1
                if (count.value === 0) {
                    form.pictureSocialNetwork.loading = false
                }
            })
        }

        if (pictureSocialNetworkLine.value.name) {
            count.value += 1

            restful.upload({ 
                id: route.params.id,
                loading: form.pictureSocialNetwork,
                file: pictureSocialNetworkLine.value, 
                field: 'line',
                store: tourStore.updatePictureSocialNetwork
            }).then((response) => {
                pictureSocialNetworkLine.value = {}

                model.picture_line_url = response.tour.picture_line_url

                count.value -= 1
                if (count.value === 0) {
                    form.pictureSocialNetwork.loading = false
                }
            })
        }
    }

    const updateStatusBadge = () => restful.update({
        id: route.params.id,
        keys: ['included_status_badge_ids', 'excluded_status_badge_ids'],
        loading: form.statusBadge,
        model: model,
        modelDefault: modelDefault,
        name: 'tour',
        store: tourStore.update,
    })

    const updateTourHotel = () => {
        const hotels = []
        for (let i = 1; i <= model.hotel_amount; i++) {
            const hotel = model.hotels.find((hotel) => hotel.pivot.day === i)
            if (hotel) {
                hotels.push(hotel)
            } else {
                hotels.push({ name: '', pivot: { day: i } })
            }
        }
        model.hotels = hotels
    }

    onMounted(() => {
        if (route.params.id) {
            if (route.params.id) {
                restful.show( tourStore.show(route.params.id) ).then((data) => { 
                    Object.assign(model, data.tour)
                    Object.assign(modelDefault, data.tour)

                    updateTourHotel()

                    initial.value = true
                })
            }
        }
    })
</script>

<template>
    <MZBreadcrumb :items="[{ label: 'Tour' }, { label: 'Edit' }]" />
    <ProgressBar v-if="! initial" mode="indeterminate" style="height: 3px" />

    <br/>

    <div v-if="initial" class="flex flex-wrap justify-content-center card-container">
        <!-- ข้อมูลหลัก -->
        <div class="col-12 lg:col-6">
            <div class="card" style="min-height: 420px;">
                <div class="card-body">
                    <div class="col-12">
                        <div class="p-fluid">
                            <h5 class="card-title">ข้อมูลหลัก</h5>
                            <h6 class="card-subtitle mb-2 text-muted"></h6>
                            <p class="card-text"></p>
                            
                            <div class="field">
                                <label for="active">ใช้งาน</label>
                                <div>
                                    <InputSwitch v-model="model.active" id="active" />
                                </div>
                            </div>
                            <div class="field">
                                <label for="code">CODE</label>
                                <InputText v-model="model.code" id="code" type="text" placeholder="XXXXXX" :class="errors.code ? 'p-invalid' : ''" />
                            </div>
                            <div class="field">
                                <label for="title">ชื่อ</label>
                                <InputText v-model="model.title" id="title" type="text" placeholder="ชิลไปไหนทราเวล" :class="errors.title ? 'p-invalid' : ''" />
                            </div>
                            <div class="field">
                                <label for="keywords">คีย์เวิร์ด</label>
                                <InputText v-model="model.keywords" id="keywords" type="text" placeholder="ทัวร์,ท่องเที่ยว,ทริป" :class="errors.keywords ? 'p-invalid' : ''"/>
                            </div>
                            <div class="field">
                                <label for="description">รายละเอียดย่อ</label>
                                <InputText v-model="model.description" id="description" type="text" placeholder="ชิลไปไหน เที่ยว 4 พัก 3" :class="errors.description ? 'p-invalid' : ''"/>
                            </div>
                            <div class="field">
                                <label for="youtube_id">Youtube ID</label>
                                <InputText v-model="model.youtube_id" id="youtube_id" type="text" placeholder="P8sqbDLISHM" :class="errors.youtube_id ? 'p-invalid' : ''" />
                            </div>

                            <br/>
                        </div>

                        <div class="text-right">
                            <Button
                                :disabled="
                                    form.main.loading
                                    || (
                                        model.code === modelDefault.code
                                        && model.active === modelDefault.active
                                        && model.title === modelDefault.title
                                        && model.keywords === modelDefault.keywords
                                        && model.description === modelDefault.description
                                        && model.youtube_id === modelDefault.youtube_id
                                    )
                                "
                                label="บันทึก" 
                                :loading="form.main.loading"
                                @click="updateMain"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- สายการบิน -->
        <div class="col-12 lg:col-6">
            <div class="card" style="min-height: 420px;">
                <div class="card-body">
                    <div class="col-12">
                        <div class="p-fluid">
                            <h5 class="card-title">สายการบิน</h5>
                            <h6 class="card-subtitle mb-2 text-muted"></h6>
                            <p class="card-text"></p>
                            
                            <div class="field">
                                <label for="airline_id">ชื่อ</label>
                                <Airline 
                                    v-if="initial" 
                                    v-model="model.airline_id" 
                                    :airline="modelDefault.airline" 
                                />
                            </div>
                            <div class="field">
                                <label for="airline_badge">ป้าย</label>
                                <InputText v-model="model.airline_badge" id="airline_badge" type="text" placeholder="บินเช้า" :class="errors.airline_badge ? 'p-invalid' : ''"/>
                            </div>
                            <div class="field">
                                <label for="location">สถานที่</label>
                                <InputText v-model="model.location" id="location" type="text" placeholder="ทัวร์พม่า - มัณฑะเลย์ พุกาม มิงกุน" :class="errors.location ? 'p-invalid' : ''"/>
                            </div>
                            <div class="field">
                                <label for="calendar_name">เดือนท่องเที่ยว</label>
                                <InputText v-model="model.calendar_name" id="calendar_name" type="text" placeholder="กันยายน" :class="errors.calendar_name ? 'p-invalid' : ''"/>
                            </div>
                            <div class="field">
                                <label for="market_price">ราคาแสดง</label>
                                <InputText v-model="model.market_price" id="market_price" type="text" placeholder="เริ่มต้น 10,000" :class="errors.market_price ? 'p-invalid' : ''"/>
                            </div>
                            

                            <br/>
                        </div>

                        <div class="text-right">
                            <Button
                                :disabled="
                                    form.airline.loading
                                    || (
                                        model.airline_id === modelDefault.airline_id
                                        && model.airline_badge === modelDefault.airline_badge
                                        && model.location === modelDefault.location
                                        && model.calendar_name === modelDefault.calendar_name
                                        && model.market_price === modelDefault.market_price
                                    )
                                "
                                label="บันทึก" 
                                :loading="form.airline.loading"
                                @click="updateAirline"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- ภาพสำหรับ Social Network-->
        <div class="col-12">
            <div class="card" style="min-height: 420px;">
                <div class="card-body">
                    <div class="flex">
                        <div class="col-3">
                            <div class="p-fluid">
                                <h5 class="card-title">Facebook</h5>
                                <h6 class="card-subtitle mb-2 text-muted">( 1200 x 630 )</h6>
                                <p class="card-text">{{ pictureSocialNetworkFacebook.name }}</p>
                                
                                <Button label="เลือก" icon="pi pi-plus" @click="$refs.refPictureSocialNetworkFacebook.click()" />
                                <input id="refPictureSocialNetworkFacebook" ref="refPictureSocialNetworkFacebook" type="file" accept="image/*" @change="handlePictureSocialNetworkFacebook( $event )"/>
                                
                                <br/>

                                <Image :src="model.picture_facebook_url || logo" preview />

                                <br/>
                            </div>
                        </div>

                        <div class="col-3">
                            <div class="p-fluid">
                                <h5 class="card-title">Twitter</h5>
                                <h6 class="card-subtitle mb-2 text-muted">( 800 x 418 )</h6>
                                <p class="card-text">{{ pictureSocialNetworkTwitter.name }}</p>
                                
                                <Button label="เลือก" icon="pi pi-plus" @click="$refs.refPictureSocialNetworkTwitter.click()" />
                                <input id="refPictureSocialNetworkTwitter" ref="refPictureSocialNetworkTwitter" type="file" accept="image/*" @change="handlePictureSocialNetworkTwitter( $event )"/>
                                
                                <br/>

                                <Image :src="model.picture_twitter_url || logo" preview />

                                <br/>
                            </div>
                        </div>

                        <div class="col-3">
                            <div class="p-fluid">
                                <h5 class="card-title">Line</h5>
                                <h6 class="card-subtitle mb-2 text-muted">( 1200 x 630 )</h6>
                                <p class="card-text">{{ pictureSocialNetworkLine.name }}</p>
                                
                                <Button label="เลือก" icon="pi pi-plus" @click="$refs.refPictureSocialNetworkLine.click()" />
                                <input id="refPictureSocialNetworkLine" ref="refPictureSocialNetworkLine" type="file" accept="image/*" @change="handlePictureSocialNetworkLine( $event )"/>
                                
                                <br/>

                                <Image :src="model.picture_line_url || logo" preview />

                                <br/>
                            </div>
                        </div>

                        <div class="col-3">
                            <div class="p-fluid">
                                <h5 class="card-title">Instagram</h5>
                                <h6 class="card-subtitle mb-2 text-muted">( 1080 x 1080 )</h6>
                                <p class="card-text">{{ pictureSocialNetworkInstagram.name }}</p>
                                
                                <Button label="เลือก" icon="pi pi-plus" @click="$refs.refPictureSocialNetworkInstagram.click()" />
                                <input id="refPictureSocialNetworkInstagram" ref="refPictureSocialNetworkInstagram" type="file" accept="image/*" @change="handlePictureSocialNetworkInstagram( $event )"/>
                                
                                <br/>

                                <Image :src="model.picture_instagram_url || logo" preview />

                                <br/>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <div class="col-12">
                        <div class="p-fluid">
                            <Button
                                :disabled="
                                    !pictureSocialNetworkFacebook.name
                                    && !pictureSocialNetworkTwitter.name
                                    && !pictureSocialNetworkLine.name
                                    && !pictureSocialNetworkInstagram.name
                                "
                                label="บันทึก" 
                                :loading="form.pictureSocialNetwork.loading"
                                @click="updatePictureSocialNetwork"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Gallery -->
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="col-12">
                        <div class="p-fluid">
                            <h5 class="card-title">Gallery</h5>
                            <h6 class="card-subtitle mb-2 text-muted"></h6>
                            <p class="card-text"></p>

                            <draggable class="flex flex-wrap" :list="model.gallery" @change="galleryReorder">
                                <div v-for="(image, index) in model.gallery" :key="index" class="col-2 card">
                                    <Image :src="image.url" />
                                    <div class="text-center">
                                        <Button icon="pi pi-times" class="p-button-danger" @click="galleryRemove(image.url)" />
                                    </div>
                                </div>
                            </draggable>

                            <ProgressBar v-if="galleryProgressValue >= 0" :value="galleryProgressValue" />

                            <br/>

                            <FileUpload name="gallery[]" :customUpload="true" @uploader="galleryUploader" :multiple="true" accept="image/*" :maxFileSize="1000000">
                                <template #empty>
                                    <p>Drag and drop files to here to upload.</p>
                                </template>
                            </FileUpload>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- ข้อมูลทั่วไป -->
        <div class="col-12 lg:col-6">
            <div class="card" style="min-height: 420px;">
                <div class="card-body">
                    <div class="col-12">
                        <div class="p-fluid">
                            <h5 class="card-title">ข้อมูลทั่วไป</h5>
                            <h6 class="card-subtitle mb-2 text-muted"></h6>
                            <p class="card-text"></p>
                            
                            <div class="field">
                                <label for="local_guide_price">ไกด์ท้องถิ่น (บาท / ท่าน)</label>
                                <InputNumber v-model="model.local_guide_price" id="local_guide_price" type="text" placeholder="1000" :class="errors.local_guide_price ? 'p-invalid' : ''" />
                            </div>
                            <div class="field">
                                <label for="flight_start_time">เวลาเริ่มบิน</label>
                                <InputMask mask="99:99:99" v-model="model.flight_start_time" id="flight_start_time" type="text" placeholder="22.00" :class="errors.flight_start_time ? 'p-invalid' : ''"/>
                            </div>
                            <div class="field">
                                <label for="flight_end_time">เวลาที่ถึง</label>
                                <InputMask mask="99:99:99" v-model="model.flight_end_time" id="flight_end_time" type="text" placeholder="06.00" :class="errors.flight_end_time ? 'p-invalid' : ''"/>
                            </div>
                            <div class="field">
                                <label for="highlight">ไฮไลท์</label>
                                <Textarea rows="4" v-model="model.highlight" id="highlight" type="text" placeholder="06.00" :class="errors.highlight ? 'p-invalid' : ''"/>
                            </div>

                            <br/>
                        </div>

                        <div class="text-right">
                            <Button
                                :disabled="
                                    form.others.loading
                                    || (
                                        model.local_guide_price === modelDefault.local_guide_price
                                        && model.flight_start_time === modelDefault.flight_start_time
                                        && model.flight_end_time === modelDefault.flight_end_time
                                        && model.highlight === modelDefault.highlight
                                    )
                                "
                                label="บันทึก" 
                                :loading="form.others.loading"
                                @click="updateOthers"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- ไฮไลท์ทัวร์ -->
        <div class="col-12 lg:col-6">
            <div class="card" style="min-height: 420px;">
                <div class="card-body">
                    <div class="col-12">
                        <div class="p-fluid">
                            <h5 class="card-title">ไฮไลท์ทัวร์</h5>
                            <h6 class="card-subtitle mb-2 text-muted"></h6>
                            <p class="card-text"></p>
                            
                            <div class="flex flex-wrap">
                                <div class="col-6">
                                    <div class="field">
                                        <label for="travel_style_type">สไตล์การเที่ยว</label>
                                        <Dropdown v-model="model.travel_style_type" id="travel_style_type" :options="[
                                            'ทั่วไป',
                                            'คุ้มค่า',
                                            'พรีเมี่ยม',
                                            'อันซีน',
                                            'ผจญภัย',
                                        ]" />
                                    </div>
                                </div>

                                <div class="col-6">
                                    <div class="field">
                                        <label for="meal_amount">มื้ออาหาร</label>
                                        <InputNumber v-model="model.meal_amount" id="meal_amount" type="text" placeholder="6" :class="errors.meal_amount ? 'p-invalid' : ''"/>
                                    </div>
                                </div>

                                <div class="col-6">
                                    <div class="field">
                                        <label for="travel_amount">ท่องเที่ยว (วัน)</label>
                                        <InputNumber v-model="model.travel_amount" id="travel_amount" type="text" placeholder="3" :class="errors.travel_amount ? 'p-invalid' : ''"/>
                                    </div>
                                </div>

                                <div class="col-6">
                                    <div class="field">
                                        <label for="hotel_amount">โรงแรม (คืน)</label>
                                        <InputNumber v-model="model.hotel_amount" id="hotel_amount" type="text" placeholder="2" :class="errors.hotel_amount ? 'p-invalid' : ''"/>
                                    </div>
                                </div>

                                <div class="col-6">
                                    <div class="field">
                                        <label for="attraction_amount">สถานที่ท่องเที่ยว (จำนวน)</label>
                                        <InputNumber v-model="model.attraction_amount" id="attraction_amount" type="text" placeholder="10" :class="errors.attraction_amount ? 'p-invalid' : ''"/>
                                    </div>
                                </div>

                                <div class="col-6">
                                    <div class="field">
                                        <label for="activity_amount">กิจกรรม (จำนวน)</label>
                                        <InputNumber v-model="model.activity_amount" id="activity_amount" type="text" placeholder="3" :class="errors.activity_amount ? 'p-invalid' : ''"/>
                                    </div>
                                </div>

                                <div class="col-6">
                                    <div class="field">
                                        <label for="shop_amount">ช้อปปิ้ง (ร้าน)</label>
                                        <InputNumber v-model="model.shop_amount" id="shop_amount" type="text" placeholder="1" :class="errors.shop_amount ? 'p-invalid' : ''"/>
                                    </div>
                                </div>

                                <div class="col-6">
                                    <div class="field">
                                        <label for="free_day_amount">วันอิสระ (วัน)</label>
                                        <InputNumber v-model="model.free_day_amount" id="free_day_amount" type="text" placeholder="0" :class="errors.free_day_amount ? 'p-invalid' : ''"/>
                                    </div>
                                </div>
                            </div>
                            
                            <br/>
                        </div>

                        <div class="text-right">
                            <Button
                                :disabled="
                                    form.highlight.loading
                                    || (
                                        model.travel_style_type === modelDefault.travel_style_type
                                        && model.meal_amount === modelDefault.meal_amount
                                        && model.travel_amount === modelDefault.travel_amount
                                        && model.hotel_amount === modelDefault.hotel_amount
                                        && model.attraction_amount === modelDefault.attraction_amount
                                        && model.activity_amount === modelDefault.activity_amount
                                        && model.shop_amount === modelDefault.shop_amount
                                        && model.free_day_amount === modelDefault.free_day_amount
                                    )
                                "
                                label="บันทึก" 
                                :loading="form.highlight.loading"
                                @click="updateHighlight"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- อัพโหลด PDF -->
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="col-12">
                        <div class="p-fluid">
                            <h5 class="card-title">อัพโหลด PDF</h5>
                            <h6 class="card-subtitle mb-2 text-muted"></h6>
                            <p class="card-text"></p>

                            <div>
                                <div v-if="model.pdf_url">
                                    ไฟล์ : <a :href="model.pdf_url" target="_blank">{{ model.pdf_url }}</a>
                                </div>
                                <div v-if="filePDF.name">
                                    กำลังอัพโหลด : {{ filePDF.name }}
                                </div>
                            </div>
                            <br/>
                        </div>

                        <div>
                            <Button label="เลือก" icon="pi pi-plus" @click="$refs.uploadPdf.click()" :loading="form.pdf.loading" />
                            <input id="uploadPdf" ref="uploadPdf" type="file" accept="application/pdf" @change="handleFileUploadPDF( $event )"/>

                            <span class="px-2" />

                            <Button
                                :disabled="! form.pdf.upload"
                                icon="pi pi-arrow-up"
                                label="อัพโหลด" 
                                :loading="form.pdf.loading"
                                @click="updatePDF"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- สถานที่ท่องเที่ยว -->
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="col-12">
                        <div class="p-fluid">
                            <h5 class="card-title">สถานที่ท่องเที่ยว</h5>
                            <h6 class="card-subtitle mb-2 text-muted"></h6>
                            <p class="card-text"></p>

                            <Attractions v-if="model.attraction_ids" v-model="model.attraction_ids"/>
                            
                            <br/>
                        </div>

                        <div class="text-right">
                            <Button
                                :disabled="
                                    form.attractions.loading
                                    || (
                                        JSON.stringify(model.attraction_ids) === JSON.stringify(modelDefault.attraction_ids)
                                    )
                                "
                                label="บันทึก" 
                                :loading="form.attractions.loading"
                                @click="updateAttractions"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- หมวดหมู่ -->
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="col-12">
                        <div class="p-fluid">
                            <h5 class="card-title">หมวดหมู่</h5>
                            <h6 class="card-subtitle mb-2 text-muted"></h6>
                            <p class="card-text"></p>

                            <Categories v-if="model.categories" v-model="model.categories"/>
                            
                            <br/>
                        </div>

                        <div class="text-right">
                            <Button
                                :disabled="
                                    form.categories.loading
                                    || (
                                        JSON.stringify(model.categories.map(x => x.id)) === JSON.stringify(modelDefault.categories.map(x => x.id))
                                    )
                                "
                                label="บันทึก" 
                                :loading="form.categories.loading"
                                @click="updateCategories"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- คอลเลกชั่น -->
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="col-12">
                        <div class="p-fluid">
                            <h5 class="card-title">คอลเลกชั่น</h5>
                            <h6 class="card-subtitle mb-2 text-muted"></h6>
                            <p class="card-text"></p>

                            <Collections v-if="model.collections" v-model="model.collections"/>
                            
                            <br/>
                        </div>

                        <div class="text-right">
                            <Button
                                :disabled="
                                    form.collections.loading
                                    || (
                                        JSON.stringify(model.collections.map(x => x.id)) === JSON.stringify(modelDefault.collections.map(x => x.id))
                                    )
                                "
                                label="บันทึก" 
                                :loading="form.collections.loading"
                                @click="updateCollections"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- มื้ออาหาร -->
        <div class="col-12 lg:col-6">
            <div class="card" style="min-height: 420px;">
                <div class="card-body">
                    <div class="col-12">
                        <div class="p-fluid">
                            <h5 class="card-title">มื้ออาหาร</h5>
                            <h6 class="card-subtitle mb-2 text-muted"></h6>
                            <p class="card-text"></p>

                            <Meals 
                                v-if="modelDefault.meal_amount > 0 && modelDefault.travel_amount > 0" 
                                v-model="model.meals" 
                                :mealAmount="modelDefault.meal_amount" 
                                :travelAmount="modelDefault.travel_amount" 
                            />

                            <br/>
                        </div>

                        <div class="text-right">
                            <Button
                                :disabled="form.meals.loading
                                    || (
                                        JSON.stringify(model.meals) === JSON.stringify(modelDefault.meals)
                                    )
                                "
                                label="บันทึก" 
                                :loading="form.meals.loading"
                                @click="updateMeals"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- ส่วนประกอบเพิ่มเติม -->
        <div class="col-12 lg:col-6">
            <div class="card" style="min-height: 420px;">
                <div class="card-body">
                    <div class="col-12">
                        <div class="p-fluid">
                            <h5 class="card-title">ส่วนประกอบเพิ่มเติม</h5>
                            <h6 class="card-subtitle mb-2 text-muted"></h6>
                            <p class="card-text"></p>
                    
                            <div class="flex flex-wrap">
                                <div class="col-6">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title">ค่าทัวร์นี้รวม</h5>

                                            <StatusBadgeInclude v-if="model.included_status_badge_ids" v-model="model.included_status_badge_ids" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title">ไม่รวม</h5>

                                            <StatusBadgeExclude v-if="model.excluded_status_badge_ids" v-model="model.excluded_status_badge_ids" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <br/>
                        </div>

                        <div class="text-right">
                            <Button
                                :disabled="
                                    form.statusBadge.loading
                                    || (
                                        JSON.stringify(model.included_status_badge_ids) === JSON.stringify(modelDefault.included_status_badge_ids)
                                        && JSON.stringify(model.excluded_status_badge_ids) === JSON.stringify(modelDefault.excluded_status_badge_ids)
                                    )
                                "
                                label="บันทึก" 
                                :loading="form.statusBadge.loading"
                                @click="updateStatusBadge"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- เงื่อนไขทัวร์ -->
        <div class="col-12 lg:col-6">
            <div class="card" style="min-height: 420px;">
                <div class="card-body">
                    <div class="col-12">
                        <div class="p-fluid">
                            <h5 class="card-title">เงื่อนไขทัวร์</h5>
                            <h6 class="card-subtitle mb-2 text-muted"></h6>
                            <p class="card-text"></p>
                    
                            <Editor v-model="model.condition_normal" editorStyle="height: 320px">
                                <template v-slot:toolbar>
                                    <span class="ql-formats">
                                        <select class="ql-header" />
                                    </span>

                                    <span class="ql-formats">
                                        <button class="ql-bold" />
                                        <button class="ql-italic" />
                                        <button class="ql-underline" />
                                        <button class="ql-strike" />
                                    </span>

                                    <span class="ql-formats">
                                        <select class="ql-color" />
                                        <select class="ql-background" />
                                    </span>

                                    <span class="ql-formats">
                                        <button class="ql-list" value="ordered" />
                                        <button class="ql-list" value="bullet" />
                                        <select class="ql-align" />
                                    </span>

                                    <span class="ql-formats">
                                        <button class="ql-link" />
                                        <button class="ql-image" />
                                        <button class="ql-blockquote" />
                                        <button class="ql-code-block" />
                                    </span>
                                </template>
                            </Editor>

                            <br/>
                        </div>

                        <div class="text-right">
                            <Button
                                :disabled="
                                    form.conditionNormal.loading
                                    || (
                                        JSON.stringify(model.condition_normal) === JSON.stringify(modelDefault.condition_normal)
                                    )
                                "
                                label="บันทึก" 
                                :loading="form.conditionNormal.loading"
                                @click="updateConditionNormal"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- เงื่อนไขคืนเงิน -->
        <div class="col-12 lg:col-6">
            <div class="card" style="min-height: 420px;">
                <div class="card-body">
                    <div class="col-12">
                        <div class="p-fluid">
                            <h5 class="card-title">เงื่อนไขคืนเงิน</h5>
                            <h6 class="card-subtitle mb-2 text-muted"></h6>
                            <p class="card-text"></p>
                    
                            <Editor v-model="model.condition_refund" editorStyle="height: 320px">
                                <template v-slot:toolbar>
                                    <span class="ql-formats">
                                        <select class="ql-header" />
                                    </span>

                                    <span class="ql-formats">
                                        <button class="ql-bold" />
                                        <button class="ql-italic" />
                                        <button class="ql-underline" />
                                        <button class="ql-strike" />
                                    </span>

                                    <span class="ql-formats">
                                        <select class="ql-color" />
                                        <select class="ql-background" />
                                    </span>

                                    <span class="ql-formats">
                                        <button class="ql-list" value="ordered" />
                                        <button class="ql-list" value="bullet" />
                                        <select class="ql-align" />
                                    </span>

                                    <span class="ql-formats">
                                        <button class="ql-link" />
                                        <button class="ql-image" />
                                        <button class="ql-blockquote" />
                                        <button class="ql-code-block" />
                                    </span>
                                </template>
                            </Editor>

                            <br/>
                        </div>

                        <div class="text-right">
                            <Button
                                :disabled="
                                    form.conditionRefund.loading
                                    || (
                                        JSON.stringify(model.condition_refund) === JSON.stringify(modelDefault.condition_refund)
                                    )
                                "
                                label="บันทึก" 
                                :loading="form.conditionRefund.loading"
                                @click="updateConditionRefund"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- โรงแรมและที่พัก -->
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="col-12">
                        <div class="p-fluid">
                            <h5 class="card-title">โรงแรมและที่พัก</h5>
                            <h6 class="card-subtitle mb-2 text-muted"></h6>
                            <p class="card-text"></p>

                            <div v-if="model.hotels && modelDefault.hotel_amount > 0" >
                                <div v-for="(hotel, index) in model.hotels" :key="index">
                                    <TourHotels 
                                        v-model="model.hotels[index]"
                                        :hotel="hotel"
                                        :day="index + 1"
                                    />
                                </div>

                                <br/>

                                <div>โรงแรมและที่พัก {{ modelDefault.hotel_amount }} วัน</div>
                            </div>

                            

                            <br/>
                        </div>

                        <div class="text-right">
                            <Button
                                :disabled="form.hotels.loading
                                    /*|| (
                                        JSON.stringify(model.hotels) === JSON.stringify(modelDefault.hotels)
                                    )*/
                                "
                                label="บันทึก" 
                                :loading="form.hotels.loading"
                                @click="updateHotels"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- ช่วงเดินทาง -->
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="col-12">
                        <div class="p-fluid">
                            <h5 class="card-title">ช่วงเดินทาง</h5>
                            <h6 class="card-subtitle mb-2 text-muted"></h6>
                            <p class="card-text"></p>

                            <TourPeriods v-if="model.periods" v-model="model.periods"/>
                            
                            <br/>
                        </div>

                        <div class="text-right">
                            <Button label="เพิ่ม" icon="pi pi-plus" @click="model.periods.push({ 
                                id: null,
                                title: null,
                                start_date: null,
                                end_date: null,
                                adult_price: null,
                                child_price: null,
                                adult_full_price: null,
                                child_full_price: null,
                            })" />

                            <span class="mx-2" />

                            <Button
                                :disabled="
                                    form.periods.loading
                                    /*|| (
                                        JSON.stringify(model.periods.map(x => x.id)) === JSON.stringify(modelDefault.periods.map(x => x.id))
                                    )*/
                                "
                                label="บันทึก" 
                                :loading="form.periods.loading"
                                @click="updatePeriods"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- รายละเอียดทัวร์ -->
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="col-12">
                        <div class="p-fluid">
                            <h5 class="card-title">รายละเอียดทัวร์</h5>
                            <h6 class="card-subtitle mb-2 text-muted"></h6>
                            <p class="card-text"></p>

                            <TourDetails v-if="model.details && modelDefault.travel_amount > 0" v-model="model.details" :travelAmount="modelDefault.travel_amount" />
                            
                            <br/>
                        </div>

                        <div class="text-right">
                            <Button
                                :disabled="
                                    form.details.loading
                                    /*|| (
                                        JSON.stringify(model.tourDetails) === JSON.stringify(modelDefault.details)
                                    )*/
                                "
                                label="บันทึก" 
                                :loading="form.details.loading"
                                @click="updateDetails"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    #refPictureSocialNetworkFacebook { display: none; }
    #refPictureSocialNetworkInstagram { display: none; }
    #refPictureSocialNetworkLine { display: none; }
    #refPictureSocialNetworkTwitter { display: none; }
    #uploadPdf { display: none; }

    .p-image { width: 100%; }
    .p-image::v-deep img { width: 100%; }
</style>
