import { useToast } from "primevue/usetoast"

class RESTfulModule {
  constructor() {
    this.toast = useToast()
  }

  show(request) {
    return new Promise((resolve, reject) => {
      request.then(({ data }) => {
        if ( data.error ) {
          this.toast.add({severity:'warn', summary: 'Warning', detail: data.error.message, life: 3000})
          return
        }

        resolve(data)
        
      }).catch((e) => {
        this.toast.add({severity:'error', summary: 'Error (Catch)', detail: e.message, life: 3000})
        reject(e)

      })
    })
  }

  update({ id, keys, loading, model, modelDefault, name, store }) {
    loading.value = true

    const updating = Object.fromEntries(keys.map(k => [k, model[k]]))

    return new Promise((resolve, reject) => {
      store(id, updating).then(({ data }) => {
        if (data.error) {
          this.toast.add({severity:'warn', summary: 'Warning', detail: data.error.message, life: 3000})
          reject(data)
          return
        }
  
        this.toast.add({severity:'success', summary: 'Success', detail: 'Update Successful.', life: 3000})
  
        loading.value = false

        Object.assign(modelDefault, Object.fromEntries(keys.map(k => [k, data[name][k]])))
    
        resolve(data)

      }).catch((e) => {
        this.toast.add({severity:'error', summary: 'Error (Catch)', detail: e.message, life: 3000})
        reject(e)

      })
    })
  }

  updateCustom({ data, id, keys, loading, model, modelDefault, name, store }) {
    loading.value = true

    return new Promise((resolve, reject) => {
      store(id, data).then(({ data }) => {
        if (data.error) {
          this.toast.add({severity:'warn', summary: 'Warning', detail: data.error.message, life: 3000})
          reject(data)
          return
        }
  
        this.toast.add({severity:'success', summary: 'Success', detail: 'Update Successful.', life: 3000})
  
        loading.value = false

        Object.assign(model, Object.fromEntries(keys.map(k => [k, data[name][k]])))
        Object.assign(modelDefault, Object.fromEntries(keys.map(k => [k, data[name][k]])))
    
        resolve(data)

      }).catch((e) => {
        this.toast.add({severity:'error', summary: 'Error (Catch)', detail: e.message, life: 3000})
        reject(e)

      })
    })
  }

  upload({ id, loading, file, field, store }) {
    loading.value = true

    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append([field], file)
      formData.append('type', field)

      store(id, formData, {headers: { 'Content-Type': 'multipart/form-data' }}).then(({ data }) => {
        if (data.error) {
          this.toast.add({severity:'warn', summary: 'Warning', detail: data.error.message, life: 3000})
          reject(data)
          return
        }

        loading.value = false

        this.toast.add({severity:'success', summary: 'Success', detail: 'Upload Successful.', life: 3000})

        resolve(data)

      }).catch((e) => {
        this.toast.add({severity:'error', summary: 'Error (Catch)', detail: e.message, life: 3000})
        reject(e)

      })
    })
  }
}

export { RESTfulModule };
