<script setup>
    import { computed, defineEmits, defineProps, onMounted, reactive, watch, ref } from 'vue'
    import { useStatusBadgeStore } from "@/store/status-badge"

    const statusBadgeStore = useStatusBadgeStore()

    const emit = defineEmits(['update:modelValue'])
    const props = defineProps(['modelValue'])

    const model = computed(() => props.modelValue)

    const status = reactive([])

    const statusBadges = ref([])

    watch(status, (newValue) => {
        if (newValue) {
            const valueIds = []
            Object.entries(newValue).map(([key, value]) => {
                if (value) { 
                    valueIds.push(parseInt(key))
                }
            })
            emit('update:modelValue', valueIds)
        }
    })

    onMounted(() => {
        statusBadgeStore.all().then(({ data }) => {
            statusBadges.value = data.status_badges
        })

        for (const element of model.value) {
            status[element] = true
        }
    })
</script>

<template>
    <ToggleButton 
        v-for="statusBadge in statusBadges" :key="statusBadge.id"
        :offLabel="statusBadge.name"
        :onLabel="statusBadge.name"
        v-model="status[statusBadge.id]"
    />
    
</template>

<style lang="scss" scoped>
    .p-togglebutton.p-button.p-highlight { background-color: green !important; border-color: green !important; }
    
</style>