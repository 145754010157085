import { defineStore } from 'pinia'

import axios from "@/plugins/axios"

require('dotenv').config()
const VUE_APP_ENDPOINT_URL = process.env.VUE_APP_ENDPOINT_URL

export const useStatusBadgeStore = defineStore('statusBadge', {
  actions: {
    all: () => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/status-badges/all`),
  }
})
